// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-gray-100 {
  background-color: $gray-100 !important;
}

.bg-gray-200 {
  background-color: $gray-200 !important;
}

.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-400 {
  background-color: $gray-400 !important;
}

.bg-gray-500 {
  background-color: $gray-500 !important;
}

.bg-gray-600 {
  background-color: $gray-600 !important;
}

.bg-gray-700 {
  background-color: $gray-700 !important;
}

.bg-gray-800 {
  background-color: $gray-800 !important;
}

.bg-gray-900 {
  background-color: $gray-900 !important;
}
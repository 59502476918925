html, body {
    height: 100%;
}

h1, .h1 {
    font-weight: 100;
    word-break: break-word;
}

.shadow-inset {
    box-shadow: inset $box-shadow;
}

.cursor-pointer {
    cursor: pointer;
}

//TEXT SIZE HELPERS

.text-lg {
    font-size: 1rem;
}

.text-sm {
    font-size: 0.8rem;
}

.text-xs {
    font-size: 0.75rem;
}

//DOMAIN VERIFICATION STATUES

.applicationAccepted {
    background-color: $success;
    color: white;
}

.pendingApproval {
    background-color: $info;
    color: white;
}

.applicationDenied {
    background-color: $danger;
    color: white;
}

.applicationInvalid {
    background-color: $light;
    color: #212529;
}

.applicationValid {
    background-color: $dark;
    color: white;
}

.needInfo {
    background-color: $warning;
    color: #212529;
}

// LIST GROUP OVERRIDE

.list-group {
    border-bottom: 0px solid $secondary;
    margin-bottom: $spacer / 2;
}

.list-group-horizontal .list-group-item
{
    margin: 0rem 0.5rem 1rem 0;
    border-bottom: 3px solid #abbccc;
}

.list-group-horizontal .list-group-item:hover {
    border-bottom: 3px solid $primary;
}

.list-group-horizontal .list-group-item.active {
    border-bottom: 3px solid $secondary;
}

.list-group-horizontal .list-group-item-primary {
    background: #ccd8e4;
}

//override theme 'light' color to white
.list-group-horizontal .list-group-item-light  {
    background: white;
    color: darken($primary, 6%);
}

.list-group-horizontal .list-group-item-light:hover,
.list-group-horizontal .list-group-item-light:focus {
    background: white;
    color: darken($primary, 6%);
}

// DOMAIN STATUS TEXT COLORS

.live {
    color: $success;
}

.pending-create {
    color: $warning;
}

.banned {
    color: $info;
}

.evergreen {
    color: $success;
}

.pending-delete {
    color: $danger;
}

.pending-deletion {
    color: $danger;
}

.immediate-deletion {
    color: $danger;
}

.on-hold {
    color: $warning;
}

// CATCH ACTION STATUS COLORS
.delete, .ban {
    color: $danger;
}

.monetise {
    color: $info;
}

.reserve {
    color: $warning;
}

.warehouse {
    color: $light;
}

//BREADCRUMBS

.breadcrumb-item:first-child::before {
    content: "";
}


//TEXT LINKS

.list-group a,
.table a,
#suffix-dropdown a,
a.btn
{
    text-decoration: none;
}

//TABLE OVERRIDES

.thead-dark .bi {
    color: $highlight !important;
}

.table a:hover {
    text-decoration: underline;
}

.table tr.thead-dark th,
.table tr.thead-light th {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
}

.thead-light a {
    color: darken($primary, 2%);
}

.thead-dark a {
    color: $highlight;

    &:hover {
        color: $highlight-dark;
    }
}

.table tfoot td {
    border-bottom: 0px;
}

.card .table.table-spaced td,
.card .table.table-spaced th {
    padding: 0.4rem 1.25rem;
}

//MODAL OVERRIDES

.modal-header {
    background: $secondary;
}

//modal override to allow scrolling within
.modal-content {
    max-height: 90vh;
}

@media (max-width: 575px) {
    .modal-content {
        max-height: 95vh;
    }
}

.modal-body {
    overflow-y: auto;
    padding: 1.5rem;
}


// Z-INDEX

.z-index-1 {
    z-index: 10;
}

.z-index-2 {
    z-index: 20;
}

// DROPDOWN MENU FOR SUFFIX
.suffix-dropdown-menu .dropdown-btn,
.suffix-dropdown-menu-sm .dropdown-btn,
{
    flex: 1 1 0; /*grow | shrink | basis */
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: $primary;
}

.suffix-dropdown-menu-sm .dropdown-btn {
    flex: 1 1 100%; /*grow | shrink | basis */
}

.suffix-dropdown-menu-sm {
    min-width: auto;
    box-shadow: $box-shadow;
}

@media (min-width: 769px) {
    .suffix-dropdown-menu {
        min-width: 25rem;
        box-shadow: $box-shadow;
    }

    .suffix-dropdown-menu .dropdown-btn:last-child {
        flex-grow: 0;
    }
}

// VUEJS TRANSITION SLIDE DOWN
.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.5s ease-in-out;
}

.slide-enter-to, .slide-leave-from {
    max-height: 1000px;
    overflow: hidden;
}

.slide-enter-from, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

.border-between {
  border-bottom: 1px solid rgba(216, 245, 255, 0.85);
}

:last-child.border-between {
  border-bottom: 0;
}

// SUFFIX SELECTOR
.suffix-selector-row:hover {
    background-color: $highlight;
}

@media (min-width: 769px) {
    .suffix-selector-card {
        min-width: 550px;
    }
}

@media (max-width: 991px) {
    .suffix-selector-card button {
        background: $highlight-dark;
    }
}

// SUFFIX PER ROLE
.suffix-grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

@media only screen and (max-width: 1700px) {
    .suffix-grid {
        display: grid;
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 992px) {
    .suffix-grid {
        display: grid;
        grid-template-columns: auto;
    }
}

.role-card {
    border-width: 0;
}

.role-card-selected {
    border-width: 2px;
    border-color: $secondary;
    border-style: solid;
}

// ICON ALIGNMENT OVERRIDE

.bi-chevron-right::before, .bi-chevron-left::before {
    vertical-align: -0.15rem !important;
}



/* ---------------------------------------------------
	SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
	min-height: 100%;
}

#sidebar {
	min-width: 250px;
	max-width: 250px;
	transition: all 0.3s;
	background: white;
	position: relative;
	z-index: 1000;
	flex: 0 0 250px;
}

#sidebar.active {
	margin-left: -240px;
}

#sidebar .sidebar-header {
	padding: 30px 20px;
	background: white;
}

#sidebar .sidebar-account {
	padding: 10px;
	background: $primary;
	color: white;
}

#sidebar .sidebar-account a {
	color: white;
}

#sidebar ul.components {
	// margin: 0;
}

#sidebar ul li > a {
	padding: 3px;
	display: block;
	position: relative;
	font-weight: 400;
	text-decoration: none;
}

#sidebar ul li > a:hover {
	text-decoration: underline;
}

#sidebar ul > a {
    padding: 10px 20px;
    color: $secondary;
    display: block;
    border-top: 1px solid lighten($highlight, 0.05);
    font-family: $headings-font-family;
    font-size: 1.2rem;
    font-weight: 200;
	text-decoration: none;
}

#sidebar ul li.active > a {
	// color: #fff;
	background: $highlight;
	// border-bottom: 1px solid $gray-200;
}

a[data-toggle="collapse"] {
	position: relative;
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
}

ul ul a {
	padding-left: 20px !important;
}

#sidebarCollapse {
	//display: none;
	position: absolute;
	right: -15px;
	top: 70px;
	width: 30px;
	height: 30px;
	border: 0;
	background: white;
	padding: 0.3rem;
}

nav#sidebar.active #sidebarCollapse i::before {
	font-weight: 600 !important;
	content: "\f285";
}

nav#sidebar #sidebarCollapse i::before {
	font-weight: 600 !important;
	content: "\f284";
}

#sidebarCollapse:hover {
	background: white;
	color: $primary;
	box-shadow: $box-shadow !important;
}

/* ---------------------------------------------------
	CONTENT STYLE
----------------------------------------------------- */

div#content {
	width: calc(100% - 10px);
	transition: all 0.3s;
}

/* ---------------------------------------------------
	MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 992px) {
	div#content {
		width: 100%;
		margin-left: -10px;
	}

	#sidebar {
		margin-left: -240px;
	}
	#sidebar.active {
		margin-left: 0;
	}
	#sidebarCollapse {
		display: block;
	}
}

@media (min-width: 769px) {
	#sidebar.active {
		//margin-left: 0;
	}
}

$vt-namespace: "Vue-Toastification" !default;
$vt-toast-min-width: 326px !default;
$vt-toast-max-width: 600px !default;
$vt-toast-background: #ffffff !default;

$vt-toast-min-height: 64px !default;
$vt-toast-max-height: 800px !default;

$vt-color-default: #1976d2 !default;
$vt-text-color-default: #fff !default;
$vt-color-info: $info !default;
$vt-text-color-info: #fff !default;
$vt-color-success: $success !default;
$vt-text-color-success: #fff !default;
$vt-color-warning: $warning !default;
$vt-text-color-warning: #fff !default;
$vt-color-error: $danger !default;
$vt-text-color-error: #fff !default;

$vt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
) !default;

$vt-mobile: "only screen and (max-width : 600px)" !default;
$vt-not-mobile: "only screen and (min-width : 600px)" !default;
$vt-font-family: "Lato", Helvetica, "Roboto", Arial, sans-serif !default;
$vt-z-index: 9999 !default;
